import React from "react";
import { Container, Row, Card, CardDeck } from "react-bootstrap";
import { yogaSnowSignUp } from "../../constants";

import YogaSnowImage from "../../img/yoga-and-snow-2025.JPG";
import YogaMountainImage from "../../img/yoga-mountain-retreat-2025.png";
import YogaHikeImage from "../../img/yogahike-2025.png";

import { yogaMountainRetreatSignUp, yogaHikeSignUp } from "../../constants";

const Retreats = () => {
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <div className="retreatImage headerImage"></div>
      <Container fluid style={{ backgroundColor: "#293b27" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* <Row
            style={{
              padding: "50px",
              maxWidth: "1600px",
            }}
          >
            <div style={{ color: "white" }}>
              <h2>Retreats 2025</h2>
              <p>Save the dates - more info coming soon...</p>
              <ul>
                <li>Yoga&Snow - Feb 13-16 2025 (Splügen GR)</li>
                <li>
                  Yoga Mountain Retreat (Auffahrt) - May 29-June 1 2025 (Lauenen
                  BE)
                </li>
                <li>Yoga&Hike 2025 - Aug 28-31 2024 (TBA)</li>
              </ul>
            </div>
          </Row> */}
          <Row
            style={{
              padding: "50px",
              maxWidth: "1600px",
            }}
          >
            <CardDeck
              style={{
                maxWidth: "1600px",
              }}
            >
              <div style={{ color: "white" }}>
                <h3>2025</h3>
              </div>
              <Card>
                <Card.Body>
                  <Card.Title>Yoga&Snow 2025</Card.Title>
                  <Card.Subtitle
                    style={{ color: "grey", marginBottom: "10px" }}
                  >
                    4th edition - Fully booked
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>Date</strong> February 13 - 16 2025 <br />
                    <strong>Location</strong> Splügen GR
                    <br />
                    <strong>Group size</strong> 8
                    <br />
                    <strong>Price</strong> 790 CHF
                  </Card.Text>
                  <Card.Text>
                    <img src={YogaSnowImage} alt="Yoga snow" width="100%" />
                  </Card.Text>
                  <Card.Text>
                    <i>
                      The explorative winter retreat with yoga and ski touring.
                      For those who share the love for powder and peaceful yoga
                      classes - the perfect blend of adventure and serenity.
                      Skitouring experience is a pre-requisite to join this
                      retreat, you will at least need the avalanche course.
                    </i>
                  </Card.Text>
                  <Card.Text>
                    Join the Yoga&Snow retreat in 2025. 3 ski tours guided by a
                    professional mountain guide, lots of yoga with me, swimming
                    pool and sauna in the renowned{" "}
                    <a
                      href="https://www.hotel-bodenhaus.ch/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hotel Bodenhaus
                    </a>{" "}
                    in Splügen. Double rooms & 4-person room available.
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <a
                    href={yogaSnowSignUp}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more & sign up for the waitlist{" "}
                  </a>
                </Card.Footer>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Yoga Mountain Retreat 2025</Card.Title>
                  <Card.Subtitle
                    style={{ color: "grey", marginBottom: "10px" }}
                  >
                    4th edition - Fully booked, waiting list
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>Date</strong> May 29 - June 1 2024 (Ascension){" "}
                    <br />
                    <strong>Location</strong> Lauenen bei Gstaad BE, Switzerland{" "}
                    <br />
                    <strong>Group size</strong> 10
                    <br />
                    <strong>Price</strong> 454-981 CHF
                  </Card.Text>
                  <Card.Text>
                    <img
                      src={YogaMountainImage}
                      alt="Yoga mountain retreat flyer"
                      width="100%"
                    />
                  </Card.Text>
                  <Card.Text>
                    <i>
                      The classic spring yoga retreat. In a beautiful &
                      comfortable accomodation (double & single rooms), we will
                      practice yoga together, deepen our understanding of all
                      aspects of yoga, enjoy nature & great food. This retreat
                      has a strong focus on mindfulness and relaxation with the
                      goal to recharge your body and soul.
                    </i>
                  </Card.Text>
                  <Card.Text>
                    This year, the yoga mountain retreat will take place again
                    in the beautiful{" "}
                    <a
                      href="https://www.alpenland.ch/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hotel Alpenland Lauenen
                    </a>
                    . We will be nurturing our bodies with local and healthy
                    food from the 13 Gault Millau points restaurant, sit
                    together in meditation, move & explore our bodies and minds
                    during yoga practice. The retreat shall be an opportunity
                    for you to go inwardly.
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <a
                    href={yogaMountainRetreatSignUp}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more & sign up here
                  </a>
                </Card.Footer>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Yoga&Hike 2025</Card.Title>
                  <Card.Subtitle
                    style={{ color: "grey", marginBottom: "10px" }}
                  >
                    7th edition - Fully booked, waitlist
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>Date</strong> Aug 28 - 31 2025 <br />
                    <strong>Location</strong> Glarus <br />
                    <strong>Group size</strong> 11
                    <br />
                    <strong>Price</strong> 313-399 CHF
                  </Card.Text>
                  <Card.Text>
                    <img
                      src={YogaHikeImage}
                      alt="Yoga and hike flyer"
                      width="100%"
                    />
                  </Card.Text>
                  <Card.Text>
                    <i>
                      The Yoga&Hike retreat is a challenging trekking retreat
                      where we will be hiking from hut to hut and practice yoga
                      whenever possible out in nature. Accomodations are low
                      cost & low comfort. Join this retreat if you are up for a
                      mountain adventure that will nurture you physically and
                      mentally.
                    </i>
                  </Card.Text>
                  <Card.Text>
                    This year's Yoga&Hike will bring us to Glarus where we will
                    go on a quest to find 230 Mio. year old dinosaur traces off
                    the beaten path. Expect to find wild nature, rough mountain
                    landscapes, cozy and simple huts (e.g.,{" "}
                    <a href="https://www.claridenhuette.ch/huette/">
                      Claridenhütte
                    </a>
                    ), and fantastic glacier views during this yoga and hike.
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <a
                    href={yogaHikeSignUp}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more & sign up here
                  </a>
                </Card.Footer>
              </Card>
              {/* <Card>
                <Card.Body>
                  <Card.Title>Alpine Yoga Retreat 2023</Card.Title>
                  <Card.Subtitle
                    style={{ color: "grey", marginBottom: "10px" }}
                  >
                    1st edition - 3 spots available
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>Date</strong> Nov 2 - 5 2023 <br />
                    <strong>Location</strong> Valle di Blenio, Switzerland{" "}
                    <br />
                    <strong>Group size</strong> 15
                    <br />
                    <strong>Price</strong> 547/467 CHF
                  </Card.Text>
                  <Card.Text>
                    <img
                      src={AlpineYogaRetreatImage}
                      alt="Alpine yoga retreat flyer"
                      width="100%"
                    />
                  </Card.Text>
                  <Card.Text>
                    <i>
                      Join us for the first edition of our Alpine Yoga Retreat
                      in November taking place in the beautiful & remote Valle
                      di Blenio in Switzerland. 4 days enjoying outdoor
                      activities in the surrounding nature, relaxing yoga &
                      meditation classes, and gift ourselves with wellness &
                      relaxation - the perfect autumn escape to recharge your
                      batteries before winter starts.
                    </i>
                  </Card.Text>
                  <Card.Text>
                    We will be staying in the beautiful and remotely located{" "}
                    <a
                      href="https://campralodge.ch/en/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Campra Alpine Lodge & Spa
                    </a>{" "}
                    in Olivone from which we can venture out into our outdoor
                    activities in the surrounding alpine nature.
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <a
                    href={signUpAlpineYogaRetreat}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more & sign up
                  </a>
                </Card.Footer>
              </Card>
            </CardDeck>*/}
            </CardDeck>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Retreats;
